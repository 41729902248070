// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {VERSION} from "./version";

export const environment = {
  production: false,
  appVersion: VERSION.version+"."+VERSION.raw+"-dev",
  apiUri: 'https://devapi.mhiapps.com',
  appUri: 'https://ngdev.mhiapps.com',
  loginUri: 'https://dev.mhiapps.com',
  ssoLoginUri: 'https://fedsvc.mckesson.com/adfs/ls/',
  apiKey: "50d8166508c746b896a741ccd4e59978",
  UAMUserApiEndpoint: "/user",
  b2c: {
    RedirectUrl: "https://ngdev.mhiapps.com",
 }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
